import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  

//添加产品
export const addProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/product/add`,pm);
}
//修改产品
export const editProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/product/update`,pm);
}
//删除产品
export const deteleProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/product/delete`,pm);
}
//获取列表
export const getList = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/product/getList`,pm);
}
//查询单条信息
export const getOne = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/product/getOne`,pm);
}
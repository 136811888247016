import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  

//查看文章
export const getListxw = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/article/getList`,pm);
}
//修改软件
export const updatexw = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/article/update`,pm);
}
//添加文章
export const addxw = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/article/add`,pm);
}
//删除文章
export const deletexw = params => {
    let pm = qs.stringify(params)
    return axios.post(`${baseUrl}/mweb/article/delete`,pm);
}
//查询单条文章
export const getOnexw = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/article/getOne`,pm);
}